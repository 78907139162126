import { extendTheme, defineStyleConfig } from '@chakra-ui/react';

const colors = {
  system: {
    primary: '#C45F81',
    primaryLight: '#c45f8112',
    blue: '#3633DD',
    pink: '#C45F81',
    yellow: '#FFD442',

    // general
    white: '#FFFFFF',
    black: '#050517',
    error: '#f47777',
    success: '#05952E',
  },
  primary: {
    text: '#141414',
  },
};

const Button = defineStyleConfig({
  baseStyle: {
    fontFamily: '"Hind"',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0.5rem',
    border: 'none',
    textAlign: 'center',
    color: 'primary.text',
    fontWeight: 500,
    transition: 'all 200ms',

    _hover: {
      cursor: 'pointer',
    },
  },
  sizes: {
    big: {
      padding: '1rem 1.5rem',
      fontSize: '16px',
    },
    small: {
      padding: '0.75rem 1.5rem',
      borderRadius: '0.75rem',
    },
  },
  variants: {
    primary: {
      background: 'system.pink',
      border: 'none',
      color: 'white',

      _hover: {
        backgroundColor: 'system.pink',

        _disabled: {
          background: '#e7a5bb',
        },
      },

      _disabled: {
        background: '#e7a5bb',
        opacity: 1,
      },
    },
    secondary: {
      background: '#233E20',
      color: '#FFD442',
      border: 'none',

      _hover: {
        backgroundColor: '#233E20',
      },
    },
  },
  defaultProps: {
    size: 'big',
    variant: 'primary',
  },
});

const textStyles = {
  h1: {
    fontSize: ['28px', '34px'],
    lineHeight: '120%',
    letterSpacing: '-0.6px',
    fontWeight: 800,
  },
  h2: {
    fontSize: ['32px', '32px'],
    fontWeight: 900,
    lineHeight: '132%',
    letterSpacing: '4%',
  },
  h3: {
    fontSize: ['24px', '24px'],
    fontWeight: 700,
  },
  h4: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '132%',
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '140%',
  },
  body: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
  },
  caption: {
    fontSize: '18px',
    fontWeight: 400,
  },
  small: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
  },
};

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: '#141414',
        fontFamily: '"Hind"',
      },
    },
  },
  colors,
  textStyles,
  components: {
    Button,
    Text: {
      baseStyle: {
        color: 'primary.text',
        lineHeight: '130%',
      },
    },
    Container: {
      baseStyle: {
        width: '100%',
        paddingLeft: '6%',
        paddingRight: '6%',
      },
      variants: {
        lg: {
          maxWidth: '71.25rem', // 1140px
        },
        md: {
          maxWidth: '66rem', // TODO: change this value to other
        },
        sm: {
          maxWidth: '27rem', // TODO: change this value to other
        },
      },
      defaultProps: {
        variant: 'lg',
      },
    },
  },
});

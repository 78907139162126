import { Currency } from '../types/general';

const isWindowDefined = (): boolean => typeof window !== 'undefined';

const scrollToElement = (elementId: string) => {
  if (isWindowDefined()) {
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
};

const formatPrice = (price: number, currency?: string, round?: number, minRound?: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || Currency.USD,
    maximumSignificantDigits: round,
    minimumSignificantDigits: minRound,
  }).format(price);

const mergeDeep = (target: Record<string, unknown>, ...sources: Record<string, unknown>[]): Record<string, unknown> => {
  if (!sources.length) {
    return target;
  }

  Object.entries(sources.shift() ?? []).forEach(([key, value]) => {
    if (value) {
      if (!target[key]) {
        Object.assign(target, { [key]: {} });
      }

      if (
        value.constructor === Object ||
        (value.constructor === Array &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value.find((v) => v.constructor === Object))
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mergeDeep(target[key], value);
      } else if (value.constructor === Array) {
        Object.assign(target, {
          [key]: (value as Array<{ constructor: unknown }>).find((v) => v.constructor === Array)
            ? (target[key as string] as Array<unknown>).concat(value)
            : [...[...(target[key as string] as Array<string>), ...value]],
        });
      } else {
        Object.assign(target, { [key]: value });
      }
    }
  });

  return target;
};

const getCookie = (key: string): string | undefined =>
  Object.fromEntries(document.cookie.split('; ').map((c) => c.split('=')))?.[key] || undefined;

const getTrackingData = () => {
  const fbclid = getCookie('_fbc') || new URLSearchParams(window.location.search).get('fbclid');
  const fbPixel = getCookie('_fbp');
  const userAgent = navigator.userAgent;

  return { fbclid, fbPixel, userAgent };
};

const calculatePrices = (price: number, finalPrice: number) => {
  const priceAUD = Math.round(price * 1.493487);
  const finalPriceAUD = Math.round(finalPrice * 1.493487);
  const priceCAD = Math.round(price * 1.3573395);
  const finalPriceCAD = Math.round(finalPrice * 1.3573395);
  const priceNZD = Math.round(price * 1.6118211);
  const finalPriceNZD = Math.round(finalPrice * 1.6118211);

  return [
    {
      price,
      currency: Currency.USD,
      finalPrice,
      discount: price - finalPrice,
    },
    {
      price,
      currency: Currency.EUR,
      finalPrice,
      discount: price - finalPrice,
    },
    {
      price,
      currency: Currency.GBP,
      finalPrice,
      discount: price - finalPrice,
    },
    {
      price: priceAUD,
      currency: Currency.AUD,
      finalPrice: finalPriceAUD,
      discount: priceAUD - finalPriceAUD,
    },
    {
      price: priceCAD,
      currency: Currency.CAD,
      finalPrice: finalPriceCAD,
      discount: priceCAD - finalPriceCAD,
    },
    {
      price: priceNZD,
      currency: Currency.NZD,
      finalPrice: finalPriceNZD,
      discount: priceNZD - finalPriceNZD,
    },
  ];
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const generalUtils = {
  isWindowDefined,
  scrollToElement,
  formatPrice,
  mergeDeep,
  getCookie,
  getTrackingData,
  calculatePrices,
  capitalizeFirstLetter,
};

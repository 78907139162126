import { createContext, Dispatch, FC, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';

import { StorageType, useStorageItem } from '../hooks/useStorage';

type QuizAnswers = Record<string, unknown>;

export const UserContext = createContext<{
  user?: unknown;
  setUser: Dispatch<SetStateAction<object>>;
  quizAnswers: QuizAnswers;
  setQuizAnswers: Dispatch<SetStateAction<QuizAnswers>>;
}>({
  user: {},
  // eslint-disable-next-lint @typescript-eslint/no-empty-function
  setUser: () => {},
  quizAnswers: {},
  setQuizAnswers: () => {},
});

interface UserContextProviderProps {
  storageType?: StorageType;
  storageKey: string;
  children: ReactNode;
}

export const UserContextProvider: FC<UserContextProviderProps> = ({
  children,
  storageKey,
  storageType = StorageType.SessionStorage,
}) => {
  const [storedUser, storeUser] = useStorageItem(storageType, storageKey);
  const [storedQuizAnswers, storeQuizAnswers] = useStorageItem<QuizAnswers>(storageType, `${storageKey}:quizAnswers`);

  const [user, setUser] = useState(storedUser || {});
  const [quizAnswers, setQuizAnswers] = useState<QuizAnswers>(storedQuizAnswers || {});

  useEffect(() => {
    storeUser(user);
  }, [user, storeUser]);

  useEffect(() => {
    storeQuizAnswers(quizAnswers);
  }, [quizAnswers, storeQuizAnswers]);

  const value = useMemo(() => ({ user, setUser, quizAnswers, setQuizAnswers }), [user, quizAnswers]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

import { ChakraProvider } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { UserContextProvider } from '@shared/common';
import { AnimatePresence } from 'framer-motion';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { config } from '../config';
import { theme } from '../theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  const AppComponent = Component as unknown as FC;

  return (
    <ChakraProvider theme={theme} resetCSS={false}>
      <Head>
        <title>{`${config.name}`}</title>
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
      </Head>
      <Global
        styles={`
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
          }

          a {
            text-decoration: none;
            color: inherit;
          }
          img {
            user-drag: none;
            -webkit-user-drag: none;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }

        input:focus-visible {
          box-shadow: none !important;
        }

        .slick-slider {
          width: 100%;
        }

        .slick-slide {
          opacity: 0.6;
        }

        .slick-slide img {
          transform: scale(0.8);
          transition: all 200ms;
        }

        .slick-slide.slick-center {
          opacity: 1;
        }

        .slick-slide.slick-center img {
          transform: scale(1);
        }

        .slick-next {
          right: 20px;
          bottom: -50px;
          top: auto;
          width: 32px;
          height: 32px;
          z-index: 5;
        }

        .slick-prev {
          left: 20px;
          bottom: -50px;
          top: auto;
          width: 32px;
          height: 32px;
          z-index: 5;
        }

        .slick-dots {
          bottom: -30px;
        }

        .slick-dots li {
          margin: 0;
        }

        .slick-dots li button:before {
          color: white;
          opacity: 1;
        }

        .slick-dots li.slick-active button:before {
          color: #5B9255;
          opacity: 1;
        }

        .slick-next:before,
        .slick-prev:before {
          content: '';
        }
      `}
      />
      <UserContextProvider storageKey={config.name}>
        <AnimatePresence mode='wait' onExitComplete={() => window.scrollTo(0, 0)}>
          <QueryClientProvider client={queryClient}>
            <AppComponent {...pageProps} />
          </QueryClientProvider>
        </AnimatePresence>
      </UserContextProvider>
    </ChakraProvider>
  );
};

export default MyApp;

import { useCallback } from 'react';

import { Currency } from '../types/general';
import { generalUtils } from '../utils/general';

const { isWindowDefined } = generalUtils;

type GTMEvent = Record<string, unknown>;

type BaseEvent = {
  category: string;
  label: string | number;
};

interface ECommerce {
  value?: number;
  currency?: Currency;
  items?: Array<{ item_id?: string; item_name?: string; quantity?: number; price?: number }>;
  user?: {
    email?: string | null;
    city?: string | null;
    region?: string | null;
    country?: string | null;
  };
}

type LogEvent = BaseEvent &
  GTMEvent & {
    event: string;
  };

export const useGTMEvents = () => {
  const logEvent = useCallback((event: GTMEvent) => {
    if (isWindowDefined() && window.dataLayer) {
      window.dataLayer.push(event);
    }
  }, []);

  const trackQuizAnswer = (event: Omit<LogEvent, 'event'>) => logEvent({ event: 'quiz_question_answered', ...event });

  const trackQuizStarted = () => logEvent({ event: 'quiz_started', category: 'started', label: 'started' });

  const trackQuizFinished = () => logEvent({ event: 'quiz_finished', category: 'finished', label: 'finished' });

  const trackCheckoutClosed = () => logEvent({ event: 'checkout_closed', category: 'closed', label: 'closed' });

  const trackSuccessInitialOrder = (event: GTMEvent) => {
    const data = { ...event };
    data.ecommerce = {
      transaction_id: data?.transactionId,
      value: data?.transactionTotal,
      valueUsd: data?.transactionTotalUsd,
      currency: data?.currencyCode,
      items: [
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          item_id: data?.transactionProducts?.[0]?.sku,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          item_name: data?.transactionProducts?.[0]?.name,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          price: data?.transactionProducts?.[0]?.price,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          quantity: data?.transactionProducts?.[0]?.quantity,
        },
      ],
      user: data?.user,
    };

    logEvent({ event: data.type === 'upsell' ? 'purchase_upsell' : 'purchase_main', ...data });
  };

  const trackSuccessUpsellOrder = (order: GTMEvent) => trackSuccessInitialOrder({ event: 'purchase_upsell', ...order });

  const trackItemsAddedToCart = useCallback(
    (ecommerce: ECommerce) =>
      logEvent({
        event: 'add_to_cart',
        ecommerce,
      }),
    [logEvent],
  );

  const trackBeginCheckout = useCallback(
    (ecommerce: ECommerce) =>
      logEvent({
        event: 'begin_checkout',
        ecommerce,
      }),
    [logEvent],
  );

  const trackLeadCreated = useCallback(
    (data?: ECommerce & { leadId?: string }, userProperties?: { flow_name?: string }) => {
      logEvent({
        event: 'generate_lead',
        ecommerce: data,
        userProperties,
      });

      if (typeof fbq !== 'undefined') {
        fbq('track', 'Lead', {}, { eventID: data?.leadId });
      }
    },
    [logEvent],
  );

  const trackPlanSelection = useCallback(
    ({ category, label }: BaseEvent) => logEvent({ event: 'action_plan_selected', category, label }),
    [logEvent],
  );

  const trackLeftEmail = useCallback(
    () => logEvent({ event: 'left_email', category: 'left_email', label: 'left_email' }),
    [logEvent],
  );

  const trackPaymentSubmit = useCallback(
    (paymentProvider: string, method?: string) =>
      logEvent({ event: 'action_payment_submit', category: paymentProvider, label: method || paymentProvider }),
    [logEvent],
  );

  const trackPaymentCancellation = useCallback(
    (type: 'cancelled' | 'error', paymentProvider: string, reason?: string) =>
      logEvent({ event: `payment_cancel_${type}`, category: reason || type, label: paymentProvider }),
    [logEvent],
  );

  const trackPageview = (page: string) => logEvent({ event: `${page}_visit`, category: page, label: page });

  return {
    logEvent,
    trackQuizAnswer,
    trackPaymentSubmit,
    trackSuccessInitialOrder,
    trackSuccessUpsellOrder,
    trackItemsAddedToCart,
    trackBeginCheckout,
    trackLeadCreated,
    trackPlanSelection,
    trackLeftEmail,
    trackPaymentCancellation,
    trackQuizStarted,
    trackQuizFinished,
    trackCheckoutClosed,
    trackPageview,
  };
};

import { Button as ButtonComp, ComponentWithAs, ButtonProps, useStyleConfig } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { generalUtils } from '../utils/general';

type NewButtonProp = ComponentWithAs<
  'button',
  ButtonProps & { action?: 'navigate' | 'scroll'; value?: string; flowName?: string }
>;

export const Button: NewButtonProp = (props) => {
  const { variant, size, ...rest } = props;
  const styles = useStyleConfig('Button', { variant, size });
  const { query } = useRouter();
  const queryParams = useMemo(() => {
    if (query?.flowName || props.flowName) {
      return {
        flowName: (query.flowName as string) || props.flowName,
      };
    }

    return {};
  }, [query, props.flowName]);

  const handleClick = () => {
    if (props.value && props.action === 'scroll') {
      generalUtils.scrollToElement(props.value);
    }
  };

  const button = <ButtonComp __css={styles} onClick={handleClick} {...rest} />;

  return props.action === 'navigate' && props.value ? (
    <Link href={{ pathname: props.value, query: queryParams }} style={{ width: '100%' }}>
      {button}
    </Link>
  ) : (
    button
  );
};
